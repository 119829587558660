import React from "react"

export default function blog() {
  return (
    <section class="relative md:py-24 py-16">
      <div class="section wf-section">
        <div class="section-container-lg center z-1 w-container">
          <h1 class="heading">
            The Launch Turkey Blog.
            <br />
          </h1>
          <p class="section-paragraph">
            All you need to know to launch, run, and scale your company.
            <br />
          </p>
        </div>
      </div>

      <div
        id="w-node-_2539edf0-49e4-00e8-bcea-cfddab835f37-1adaadd0"
        class="collection-list-wrapper w-dyn-list mt-8"
      >
        <div role="list" class="collection-list-2 w-dyn-items">
          <div role="listitem" class="collection-item-5 w-dyn-item">
            <a
              href="https://www.launchturkey.com/blog/what-to-consider-before-launching-a-turkish-company"
              class="blog-post w-inline-block"
            >
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses.png"
                loading="lazy"
                alt=""
                sizes="(max-width: 479px) 91vw, (max-width: 767px) 89vw, (max-width: 991px) 132.484375px, 140.484375px"
                srcset="https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses-p-500.png 500w, https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses-p-800.png 800w, https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses-p-1080.png 1080w, https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses-p-1600.png 1600w, https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses-p-2000.png 2000w, https://assets.website-files.com/6094875660e7caf817a85eb9/63ebacccee0a9e4574c533f4_sg%26a%20expenses.png 2400w"
                class="blog-thumbnail"
              />
              <div class="blog-post-bottom">
                <h2 class="blog-post-title">What to Consider Before Launching a Turkish Company</h2>
                <div class="blog-post-author">
                  <div class="post-author-name-copy">
                    <div class="post-date">February 14, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div role="listitem" class="collection-item-5 w-dyn-item">
            <a
              href="https://www.launchturkey.com/blog/incorporating-a-company-in-turkey"
              class="blog-post w-inline-block"
            >
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog.png"
                loading="lazy"
                alt=""
                sizes="(max-width: 479px) 91vw, (max-width: 767px) 89vw, (max-width: 991px) 132.484375px, 140.484375px"
                srcset="https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog-p-500.png 500w, https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog-p-800.png 800w, https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog-p-1080.png 1080w, https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog-p-1600.png 1600w, https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog-p-2000.png 2000w, https://assets.website-files.com/6094875660e7caf817a85eb9/63e69f98f5cfe420e8f896f9_S%20Corp%20v%20C%20Corp%20blog.png 2400w"
                class="blog-thumbnail"
              />
              <div class="blog-post-bottom">
                <h2 class="blog-post-title">
                    Incorporating a Company in Turkey
                </h2>
                <div class="blog-post-author">
                  <div class="post-author-name-copy">
                    <div class="post-date">February 13, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div role="listitem" class="collection-item-5 w-dyn-item">
            <a
              href="https://www.launchturkey.com/blog/important-details-for-owners-of-a-turkish-company"
              class="blog-post w-inline-block"
            >
              <img
                src="https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog.png"
                loading="lazy"
                alt=""
                sizes="(max-width: 479px) 91vw, (max-width: 767px) 89vw, (max-width: 991px) 132.484375px, 140.484375px"
                srcset="https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog-p-500.png 500w, https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog-p-800.png 800w, https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog-p-1080.png 1080w, https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog-p-1600.png 1600w, https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog-p-2000.png 2000w, https://assets.website-files.com/6094875660e7caf817a85eb9/63da8c8af4e8d31d8d911c2a_Section%20179%20Blog.png 2400w"
                class="blog-thumbnail"
              />
              <div class="blog-post-bottom">
                <h2 class="blog-post-title">
                    Important Details for Owners of a Turkish Company
                </h2>
                <div class="blog-post-author">
                  <div class="post-author-name-copy">
                    <div class="post-date">February 1, 2023</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
